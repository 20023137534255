import { Card, Link, SvgIcon, Typography } from '@mui/material'
import { IBaseProductCard } from './baseTypes'
import { ArticleId } from '../articles/ArticleId'
import { isValidLabels, Labels } from './Labels'
import { Action } from './Action'
import { ReactComponent as PlaylistAddIcon } from '@obeta/assets/icon/playlist_add.svg'
import { ReactComponent as InfoIcon } from '@obeta/assets/icon/error_outline_24.svg'
import { ReactComponent as DeleteIcon } from '@obeta/assets/icon/designsystem/delete_outline.svg'
import { ReactComponent as CloseIcon } from '@obeta/assets/icon/designsystem/close.svg'
import styles from './Base.module.scss'
import stylesReplacementDialog from '../replacement-articles/ReplacementArticleDialog.module.scss'
import clsx from 'clsx'
import { ICartItemForPdfProps } from './makeCartItem'
import { BaseTitle } from './Title'
import { ProductOxomiImage } from '../product-images/ProductOxomiImage'
import { StockInfo } from '../stock-info/StockInfo'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useReplacementArticle } from '@obeta/data/lib/hooks/useReplacementArticle'
import { ReplacementArticleSearchLink } from './ReplacementArticleSearchLink'
import { useProductCollectionActions } from '@obeta/data/lib/hooks/useProductCollectionActions'
import { TertiaryButton } from '../custom-button/CustomButton'
import { ImgProxyImage } from '../img-proxy-image/ImgProxyImage'
import { ensureValidImgProxyUrl } from '@obeta/utils/lib/ensureValidImgProxyUrl'
import { IdsTransferProductButton } from '../ids/IdsTransferProductButton'
import { ReactComponent as ProductPlaceholderImage } from '@obeta/assets/img/CardProductPlaceholder.svg'
import { useIsIdsTransferBackPossible } from '@obeta/data/lib/hooks/ids/useIsIdsTransferBackPossible'

export const IMAGE_SIZE_AUTHENTICATED = '128'
export const IMAGE_SIZE_UNAUTHENTICATED = '223'

export const Base: React.FC<IBaseProductCard & ICartItemForPdfProps> = (props) => {
  const { t } = useTranslation()
  const {
    authenticated = true,
    layout,
    title,
    productId,
    dehaId,
    productImage,
    properties,
    isSendable,
    isCutProduct,
    productType,
    storeAddress,
    productAmount,
    stockAvailabilityEstimate,
    stocks,
    onDeleteClicked,
    onInfoClicked,
    onAddClicked,
    withLabels = true,
    className,
    loadingStocks,
    onClick,
    settings,
    onTitleClick,
    counter,
    isCartItemForPdf,
    titleLines = 2,
    orderPdfSupplierData,
    replacementProducts,
    showDeleteButtonForDiscontinuedItem,
    showPurgeButtonForReplacementItem,
    onPurgeReplacementItem,
    onDeleteItemOfChoice,
    url,
    showStockData = true,
    showIdsTransferButton,
    offerId,
    offerItemPosition,
    inlineLabelsAndSubActions,
    isFetchingImages,
    dataAttributes,
  } = props

  const { desktop } = useBreakpoints()
  const { onLinkClick } = useProductCollectionActions()
  const isIdsTransferBackPossible = useIsIdsTransferBackPossible()

  const handleOnTitleClick = (event) => {
    event.preventDefault()
    if (onTitleClick) {
      onTitleClick(event)
      onLinkClick(productId ?? '')
    }
  }

  // TODO: support for dynamic title height ? useTitleImage hook: makes sense to create enhancer
  // if this logic is needed.
  function renderProductImage() {
    const validUrl = ensureValidImgProxyUrl(url)

    if (validUrl) {
      return (
        <ImgProxyImage
          variant="product"
          className={styles.productImage}
          url={validUrl}
          title={title}
          mobileWidth={parseInt(IMAGE_SIZE_AUTHENTICATED, 10)}
          tabletWidth={parseInt(IMAGE_SIZE_AUTHENTICATED, 10)}
          tabletWideWidth={parseInt(IMAGE_SIZE_AUTHENTICATED, 10)}
          desktopWidth={parseInt(IMAGE_SIZE_AUTHENTICATED, 10)}
        />
      )
    }

    if (isFetchingImages) {
      return <ProductPlaceholderImage className={styles.productImage} />
    }

    return (
      <ProductOxomiImage key="product-image" {...productImage} className={styles.productImage} />
    )
  }

  const imageContent = renderProductImage()

  const imageElement = onTitleClick ? (
    <Link
      href={`${ShopRoutes.ArticleDetailPage}/${productId}`}
      key="link-image"
      color={'inherit'}
      underline={'none'}
      onClick={(event) => handleOnTitleClick(event)}
      className={styles.productImageWrap}
    >
      {imageContent}
    </Link>
  ) : (
    imageContent
  )
  const titleElement = onTitleClick ? (
    <Link
      href={`${ShopRoutes.ArticleDetailPage}/${productId}`}
      key="linked-title"
      color={'inherit'}
      underline={'none'}
      onClick={(event) => handleOnTitleClick(event)}
    >
      <BaseTitle
        key="product-title"
        lines={titleLines}
        variant="bodyBold"
        enforceHeight={props.enforceTitleMinHeight}
      >
        {title}
      </BaseTitle>
    </Link>
  ) : (
    <BaseTitle
      key="product-title"
      lines={titleLines}
      variant="bodyBold"
      enforceHeight={props.enforceTitleMinHeight}
    >
      {title}
    </BaseTitle>
  )
  const defaultDirection = !authenticated ? 'horizontal' : 'vertical'

  const replacementDehaIds = replacementProducts?.map(({ dehaId }) => dehaId) ?? []
  const { isReplacementArticleVisible } = useReplacementArticle({
    multipleReplacementArticleOptions: { replacementDehaIds, dehaId },
    stocks,
  })
  const showIdsTransferBackButton = isIdsTransferBackPossible && showIdsTransferButton && productId
  const showReplacementButton = stocks && isReplacementArticleVisible
  const showLabels = withLabels && isValidLabels({ isCutProduct, isSendable })
  const showSubActions = desktop && (showIdsTransferBackButton || showReplacementButton)
  const subActions = (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {showSubActions && (
        <div className={clsx(styles.subActions, showLabels && styles.aligned)}>
          {showReplacementButton && settings.statusesDirection === 'horizontal' && (
            <ReplacementArticleSearchLink
              replacementDehaIds={replacementDehaIds}
              dehaId={dehaId ?? ''}
            />
          )}
          {showIdsTransferBackButton && (
            <IdsTransferProductButton
              sapId={productId}
              amount={productAmount}
              offerId={offerId}
              offerItemPosition={offerItemPosition}
            />
          )}
        </div>
      )}
    </>
  )

  const labels = showLabels ? (
    <>
      <div className={styles.labelsContainer}>
        <Labels
          key="labels"
          onlyIcons={settings.labels === 'only-icon'}
          isSendable={isSendable}
          isCutProduct={isCutProduct}
          direction={settings.labelDirection || defaultDirection}
        />
        {inlineLabelsAndSubActions ? null : subActions}
      </div>
      {showReplacementButton && settings.statusesDirection === 'vertical' && (
        <div className={styles.replacementLinkVerticalContainer}>
          <ReplacementArticleSearchLink
            replacementDehaIds={replacementDehaIds}
            dehaId={dehaId ?? ''}
          />
        </div>
      )}
    </>
  ) : null

  const propertiesElement = properties.map((propertyData, index) => {
    const necessaryProps = { ...propertyData, isCartItemForPdf }
    return (
      <div key={propertyData.type} className={!authenticated ? styles.articleNumWithIcons : ''}>
        <ArticleId key={propertyData.type} {...necessaryProps} />
        {!authenticated && labels}
      </div>
    )
  })

  const orderPdfSupplier = orderPdfSupplierData ? (
    <div className={clsx(!authenticated ? styles.articleNumWithIcons : '')}>
      <ArticleId
        key={orderPdfSupplierData.type}
        type={orderPdfSupplierData.type}
        value={orderPdfSupplierData.value}
        isCartItemForPdf={true}
      />
      {!authenticated && labels}
    </div>
  ) : undefined

  const stockElements =
    authenticated && showStockData ? (
      <div className={styles.stocks}>
        <StockInfo
          key="statuses"
          direction={settings.statusesDirection}
          productType={productType}
          storeAddress={storeAddress}
          productAmount={productAmount}
          stockAvailabilityEstimate={stockAvailabilityEstimate}
          stocks={stocks}
          loading={loadingStocks}
        />
        {labels && !inlineLabelsAndSubActions ? null : subActions}
      </div>
    ) : null

  const actions: JSX.Element[] = []
  if (onAddClicked && authenticated) {
    actions.push(
      <Action
        key="add"
        icon={<PlaylistAddIcon data-testid="add-to-cart-template" />}
        onClick={onAddClicked}
      />
    )
  }
  if (onInfoClicked) {
    actions.push(<Action key="info" icon={<InfoIcon />} onClick={onInfoClicked} />)
  }
  if (onDeleteClicked) {
    actions.push(
      <Action
        key="delete"
        icon={
          <SvgIcon component={DeleteIcon} fontSize="inherit" title={t('MAIN.DELETE_PRODUCT')} />
        }
        onClick={onDeleteClicked}
      />
    )
  }

  if (showDeleteButtonForDiscontinuedItem) {
    actions.length = 0
    actions.push(
      <div className={stylesReplacementDialog.wrapperDeletePurgeButton}>
        <TertiaryButton
          size={desktop ? 'small' : 'large'}
          leftIcon={<SvgIcon component={DeleteIcon} />}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onDeleteItemOfChoice && onDeleteItemOfChoice(productId)
          }}
          iconColor={'primary'}
        >
          <Typography> {t('BUTTON.DELETESMALL')}</Typography>
        </TertiaryButton>
      </div>
    )
  }

  if (showPurgeButtonForReplacementItem) {
    actions.length = 0
    actions.push(
      <div className={stylesReplacementDialog.wrapperDeletePurgeButton}>
        <TertiaryButton
          size={desktop ? 'small' : 'large'}
          leftIcon={<SvgIcon component={CloseIcon} />}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onPurgeReplacementItem && onPurgeReplacementItem(productId)
          }}
          iconColor={'primary'}
        >
          <Typography> {t('BUTTON.PURGE')}</Typography>
        </TertiaryButton>
      </div>
    )
  }

  const layoutElement = layout({
    imageElement,
    titleElement,
    propertiesElements: propertiesElement,
    orderPdfSupplier: orderPdfSupplier,
    counter,
    labels: !authenticated ? null : labels,
    stockElements: stockElements,
    actionsElements: actions,
    authenticated,
  })

  return (
    <Card
      data-testid="card"
      onClick={onClick}
      className={clsx(styles.card, { [styles.stretch]: settings.stretch }, className)}
      {...dataAttributes}
    >
      {layoutElement}
    </Card>
  )
}
