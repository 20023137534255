import { IShopProductCardProps, ShopProductCard } from '.'
import { useDataAttributes } from '@obeta/data/lib/hooks/useDataAttributes'

export const ArticleCarouselCard: React.FC<
  Omit<IShopProductCardProps, 'onTitleClick' | 'onProductImageClicked' | 'withListPrice' | 'layout'>
> = (props) => {
  const { dataAttributes } = useDataAttributes()
  return (
    <ShopProductCard
      {...props}
      url={
        'images' in props.product && props.product.images && props.product.images?.length > 0
          ? props.product.images[0].url
          : ''
      }
      withListPrice={false}
      layout="vertical"
      enforceTitleMinHeight={true}
      onAddToCartClick={props.onAddToCartClick}
      dataAttributes={dataAttributes}
    />
  )
}
