import React from 'react'
import { ReactComponent as EcoIcon } from 'assets/icon/designsystem/eco.svg'
import { ReactComponent as EuroCircleIcon } from 'assets/icon/designsystem/euro_circle.svg'
import { ReactComponent as LocalOffer } from 'assets/icon/designsystem/local_offer.svg'
import { ReactComponent as LocalFireDepartment } from 'assets/icon/designsystem/local-fire-department.svg'
import { ReactComponent as NewReleases } from 'assets/icon/designsystem/new_releases.svg'
import { ReactComponent as ShoppingBag } from 'assets/icon/designsystem/shopping_bag.svg'
import { ReactComponent as Verified } from 'assets/icon/designsystem/verified.svg'
import { ReactComponent as ShoppingCart } from 'assets/icon/designsystem/shopping_cart.svg'
import { useTranslation } from 'react-i18next'
import styles from './Label.module.scss'

export interface ILabelProps {
  icon: JSX.Element
  label: string
  color: 'green' | 'red' | 'gray' | 'orange'
}
const Label: React.FC<ILabelProps> = (props) => {
  const { icon, label, color } = props
  return (
    <div className={styles.labelRoot}>
      <div className={`${styles.articleLable} ${styles.border} ${styles[color]}`}>
        <div className={`${styles.iconContainer} ${styles.background} ${styles[color]}`}>
          {icon}
        </div>
        <div className={`${styles.text} ${styles[color]}`}>{label}</div>
      </div>
    </div>
  )
}

export const LabelSustainability = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.articleLable}>
      <Label
        icon={<EcoIcon className={styles.icon} />}
        label={t('LABEL.SUSTAINABILITY')}
        color={'green'}
      />
    </div>
  )
}

export const LabelDeals = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.articleLable}>
      <Label
        icon={<EuroCircleIcon className={styles.icon} />}
        label={t('LABEL.DEALS')}
        color={'red'}
      />
    </div>
  )
}

export const LabelDealsInShop = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.articleLable}>
      <Label
        icon={<EuroCircleIcon className={styles.icon} />}
        label={t('LABEL.DEALS_IN_SHOP')}
        color={'red'}
      />
    </div>
  )
}

export const LabelSale = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.articleLable}>
      <Label icon={<LocalOffer className={styles.icon} />} label={t('LABEL.SALE')} color={'red'} />
    </div>
  )
}

export const LabelTopseller = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.articleLable}>
      <Label
        icon={<LocalFireDepartment className={styles.icon} />}
        label={t('LABEL.TOP_SELLER')}
        color={'red'}
      />
    </div>
  )
}

export const LabelNewArrival = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.articleLable}>
      <Label
        icon={<NewReleases className={styles.icon} />}
        label={t('LABEL.NEW_ARRIVAL')}
        color={'orange'}
      />
    </div>
  )
}
export interface ILabelTimesPurchasedProp {
  count: number
}
export const LabelTimesPurchased: React.FC<ILabelTimesPurchasedProp> = (props) => {
  const { count } = props
  const { t } = useTranslation()
  return (
    <div className={styles.articleLable}>
      <Label
        icon={<ShoppingBag className={styles.icon} />}
        label={`${count} ${t('LABEL.X_TIMES_PURCHASED')}`}
        color={'gray'}
      />
    </div>
  )
}

export const LabelNewInStock = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.articleLable}>
      <Label
        icon={<Verified className={styles.icon} />}
        label={t('LABEL.NEW_IN_STOCK')}
        color={'orange'}
      />
    </div>
  )
}

export const LabelInCart = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.articleLable}>
      <Label
        icon={<ShoppingCart className={styles.icon} />}
        label={t('LABEL.IN_CART')}
        color={'gray'}
      />
    </div>
  )
}
