import clsx from 'clsx'
import React, { useState } from 'react'
import { ICardWithProduct } from './withProduct'
import { PopoverMobileCounter } from '../counter/MobileCounter'
import { AddItemsToCartButton } from '../add-items-to-cart-button/AddItemsToCartButton'
import { Horizontal, IVerticalLayout, Vertical } from './layouts'
import { createRenderPricesBlock } from './layouts/renderPricesBlock'
import { ReactComponent as TecSelectIcon } from '@obeta/assets/icon/tec_select_small.svg'
import styles from './makeShopProductCard.module.scss'
import { IMapProductPricesProps } from '../product-price/ProductPrice'
import { DropdownTemplatesBase } from '../dropdown-templates/DropdownTemplates'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { productHasTecSelect } from '@obeta/utils/lib/productHasTecSelect'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { MaybeCompleteSearchProduct } from '@obeta/models/lib/schema-models/search'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { Bottom } from './layouts/Bottom'
import { DropdownTemplatesType } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { RecommendationProduct } from '@obeta/models/lib/schema-models/recommendation'
import { useProductCard } from '@obeta/data/lib/hooks/useProductCard'
import { LabelInCart, LabelTimesPurchased, LabelTopseller } from '../label/Label'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { extractNumberFromText } from '@obeta/utils/lib/extractNumberFromText'

type Variant = 'small' | 'big'

export interface IShopProductCardProps
  extends Omit<
    ICardWithProduct,
    | 'onDeleteClicked'
    | 'onInfoClicked'
    | 'onAddClicked'
    | 'layout'
    | 'topAdornments'
    | 'leftAdornments'
    | 'productUnit'
    | 'counter'
    | 'productAmount'
    | 'withLabels'
    | 'bottomAdornments'
    | 'mapValues'
    | 'settings'
    | 'product'
  > {
  layout: 'vertical' | 'horizontal'
  tablet: boolean
  mobile: boolean
  withListPrice: boolean
  initialProductAmount?: number
  user: UserV2 | null
  isLoggedIn: boolean
  carts: ShoppingCartV2[]
  selectedCart: ShoppingCartV2 | undefined
  withLabels?: boolean
  noTopAdornments?: boolean
  onAddToCartClick?: (selectedCartId: string) => void
  product: ProductAggregate | MaybeCompleteSearchProduct | RecommendationProduct
}

export const makeShopProductCard = (
  Card: React.FC<ICardWithProduct>
): React.FC<IShopProductCardProps> => {
  return (props: IShopProductCardProps) => {
    const {
      product,
      mobile,
      tablet,
      layout,
      withListPrice,
      initialProductAmount = product.minimumAmount,
      user,
      isLoggedIn,
      carts,
      selectedCart,
      onAddToCartClick,
      ...rest
    } = props

    const horizontal = mobile ? false : layout === 'horizontal'
    const layoutType = horizontal ? 'horizontal' : 'vertical'

    const [productAmount, setProductAmount] = useState(initialProductAmount)
    const [showMobileCounter, setShowMobileCounter] = useState(false)
    const { handleClick: showDropdownTemplates, ...dropdownTemplates } = usePopoverState()
    const mobileCounterAvailable = Boolean(mobile || tablet)

    const { setAmountRoundUpNotification } = useProductCard()

    let variant: Variant = 'small'
    if (!mobile && !horizontal) {
      variant = 'big'
    }

    let productPrices
    if ('prices' in product) {
      productPrices = product.prices
    }

    const prices: IMapProductPricesProps['prices'] = [
      {
        type: 'PurchasePrice',
        value: productPrices?.netPrice,
        oldValue: productPrices?.strikeThroughPrice,
        loading: productPrices?.netPrice === undefined,
        unit: product.unit,
      },
    ]

    if (withListPrice) {
      prices.push({
        type: 'ListPrice',
        value: productPrices?.listPrice,
        loading: productPrices?.listPrice === undefined,
        unit: product.unit,
      })
    }

    const renderPricesBlock = createRenderPricesBlock(
      {
        pricePermissions: user?.permissions?.Global_canReadPrices,
        prices,
        currency: productPrices?.currency || '',
        priceDimension: product.priceDimension,
      },
      layoutType,
      isLoggedIn
    )

    const [topAdornments, setTopAdornments] = useState<JSX.Element[]>([])

    const tecSelectAvailable = productHasTecSelect(productPrices)
    if (tecSelectAvailable && !topAdornments.some((item) => item.key === 'tec-select')) {
      topAdornments.push(<TecSelectIcon key="tec-select" />)
    }

    const isShowLabelAlreadyinCart = useFeatureToggle('UseShowLabelAlreadyinCart')
    if (
      selectedCart?.items.some((item) => item.sapId === product.sapId && isShowLabelAlreadyinCart)
    ) {
      topAdornments.push(<LabelInCart />)
    }

    const isShowLabelXTimesPurchased = useFeatureToggle('UseShowLabelXTimesPurchased')
    const isShowLabelTopseller = useFeatureToggle('UseShowLabelTopseller')

    let titleLines
    if (mobile || tablet) {
      titleLines = 3
    } else if (layout === 'vertical') {
      titleLines = 3
    } else {
      titleLines = 2
    }
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const data: any = null
    const getTopAdornments = (productData) => {
      const newTopAdornments = [...topAdornments]

      const boughtXTimesLabel = productData.labels.find((label) => label.type === 'boughtXTimes')

      if (
        boughtXTimesLabel &&
        !newTopAdornments.some((item) => item.key === 'boughtXTimes') &&
        isShowLabelXTimesPurchased
      ) {
        const count = extractNumberFromText(boughtXTimesLabel.text)

        if (count !== null) {
          newTopAdornments.push(
            <div key="boughtXTimes">
              <LabelTimesPurchased count={count} />
            </div>
          )
        }
      }
      const topseller = productData.labels.find((label) => label.type === 'topseller')
      if (
        topseller &&
        !newTopAdornments.some((item) => item.key === 'topseller') &&
        isShowLabelTopseller
      ) {
        newTopAdornments.push(
          <div key="topseller">
            <LabelTopseller />
          </div>
        )
      }

      return newTopAdornments
    }
    const productData = data?.getProducts.find((item) => item.sapId === product.sapId)

    if (productData) {
      const newTopAdornments = getTopAdornments(productData)

      if (newTopAdornments.length !== topAdornments.length) {
        setTopAdornments(newTopAdornments)
      }
    }

    return (
      <>
        <Card
          withLabels={props.withLabels}
          user={user}
          authenticated={isLoggedIn}
          settings={{
            labels: 'only-icon',
            statusesDirection: layoutType,
            labelDirection: layout === 'vertical' && mobile ? 'horizontal' : undefined,
            stretch: horizontal,
          }}
          onAddClicked={showDropdownTemplates}
          productAmount={productAmount}
          product={product}
          onDeleteClicked={null}
          onInfoClicked={null}
          productUnit={null}
          className={clsx({
            [styles.vertical]: !horizontal,
          })}
          stockAvailabilityEstimate={
            'stockAvailabilityEstimate' in product
              ? product?.stockAvailabilityEstimate ?? null
              : null
          }
          titleLines={titleLines}
          layout={(layoutProps) => {
            const { counter } = layoutProps

            const bottomAdornments = isLoggedIn
              ? [
                  <AddItemsToCartButton
                    key="add-items-to-cart-button"
                    carts={carts}
                    selectedCart={selectedCart}
                    mobile={false}
                    product={product}
                    productAmount={productAmount}
                    size={mobile || tablet || layout === 'vertical' ? 'large' : 'small'}
                    onAddToCartClick={onAddToCartClick}
                    algoliaUserToken={user?.algoliaUserToken}
                  />,
                ]
              : null

            const verticalLayoutConfig: IVerticalLayout = {
              dividers: {
                properties: layoutProps.authenticated,
                statuses: false,
                values: false,
              },
              order: {
                properties: 0,
                values: 2,
                statuses: 3,
              },
              actionsPlacement: 'right',
            }
            return horizontal ? (
              <Horizontal
                productImageSize={'128'}
                mobile={true}
                mapValues={renderPricesBlock}
                topAdornments={topAdornments}
                leftAdornments={null}
                bottomAdornments={
                  tablet || mobile
                    ? [
                        <Bottom
                          key="bottom-adornments"
                          className={styles.bottomTablet}
                          direction="horizontal"
                        >
                          {counter}
                          {bottomAdornments}
                        </Bottom>,
                      ]
                    : null
                }
                bottomRightAdornments={!tablet && !mobile ? bottomAdornments : null}
                {...layoutProps}
                counter={!tablet && !mobile ? counter : null}
              />
            ) : (
              <Vertical
                {...layoutProps}
                layoutConfig={verticalLayoutConfig}
                values={renderPricesBlock(undefined, {
                  mainPriceFont: 'headline3Bold',
                })}
                stackStatus={mobile}
                topAdornments={topAdornments}
                leftAdornments={null}
                titleLines={3}
                bottomAdornments={bottomAdornments}
              />
            )
          }}
          counter={{
            changeProductAmount: setProductAmount,
            variant: variant,
            stretchVertical: !horizontal || mobile || tablet,
            disabled: false,
            readonly: mobileCounterAvailable,
            initialAmount: initialProductAmount,
            onInputValidation: (valid, amountToUse, amount) => {
              if (!valid && product) {
                setAmountRoundUpNotification(product?.sapId, amountToUse, amount, product?.unit)
              }
            },
            onTextFieldClicked: () => {
              setShowMobileCounter(true)
            },
          }}
          {...rest}
        />
        {mobileCounterAvailable && (
          <PopoverMobileCounter
            open={showMobileCounter}
            onClose={function () {
              setShowMobileCounter(false)
            }}
            unit={product.unit}
            minimumAmount={product.minimumAmount}
            initialAmount={initialProductAmount}
            maxAcceptableAmount={9999}
            onAccept={function (value: number): void {
              setProductAmount(value)
              setShowMobileCounter(false)
            }}
          />
        )}
        <DropdownTemplatesBase
          dropdown={dropdownTemplates}
          mobile={false}
          productsToAdd={[{ productId: product.sapId, amount: productAmount }]}
          templatesType={DropdownTemplatesType.ADD_ARTICLES}
        />
      </>
    )
  }
}
