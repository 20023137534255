/**
 *
 * @param {number} amount - number of items * multipleOf
 * @param {number} multipleOf - aka "minAmount"
 * @returns {boolean} - is amount realla produce of number of items * multipleOf
 */
export const isValidAmount = (amount: number, multipleOf: number) => {
  if (amount < multipleOf || amount % multipleOf !== 0) {
    return false
  }
  return true
}

export const defaultMaxAcceptableAmount = 999999

export const getNextValidAmount = (
  amount: number,
  minimumAmount: number,
  maxAmount: number | undefined,
  maxAcceptableAmount = defaultMaxAcceptableAmount
) => {
  let nextValidAmount = amount
  if (isNaN(nextValidAmount)) {
    return { isValid: false, nextValidAmount: minimumAmount }
  }
  const isValid = isValidAmount(amount, minimumAmount)
  if (!isValid) {
    nextValidAmount =
      amount < minimumAmount ? minimumAmount : Math.ceil(amount / minimumAmount) * minimumAmount
  }

  nextValidAmount = Math.min(
    nextValidAmount,
    Math.floor(maxAcceptableAmount / minimumAmount) * minimumAmount
  )

  if (isNaN(nextValidAmount)) {
    return { isValid: false, nextValidAmount: minimumAmount }
  }

  return { isValid, nextValidAmount }
}
