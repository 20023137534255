import React, { useEffect, useRef, useState, useCallback, FocusEvent } from 'react'
import { Box, Button, SvgIcon, TextField, useTheme } from '@mui/material'
import { ReactComponent as MinusIcon } from 'assets/icon/designsystem/remove.svg'
import { ReactComponent as PlusIcon } from 'assets/icon/designsystem/add.svg'
import clsx from 'clsx'
import { defaultMaxAcceptableAmount, getNextValidAmount } from './helpers'
import styles from './Counter.module.scss'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { DataAttributes } from '@obeta/utils/lib/types-dom'

interface IClasses {
  amountField?: string
}

export interface CounterProps {
  minimumAmount: number
  /** I added support for initial amount in order to save user from recalculating this value himself
   * Because usually user gets this value asyncronously
   *
   */
  maxAmount?: number
  initialAmount: number | null | undefined
  amount: number
  changeProductAmount: React.Dispatch<React.SetStateAction<number>>
  variant: 'small' | 'big'
  isZeroAccepted?: boolean
  disabled?: boolean
  autofocusOnRender?: boolean
  delayAutofocus?: boolean
  classes?: IClasses
  minusButtonTabIndex?: number
  plusButtonTabIndex?: number
  stretchHorizontal?: boolean
  stretchVertical?: boolean
  textFieldTabIndex?: number
  onInputValidation?: (valid: boolean, amountToUse: number, amount: number) => void
  onSubmit?: (amount: number, userInputAmount: number) => void
  onTextFieldClicked?: (e: React.MouseEvent<HTMLInputElement>) => void
  selectedCart?: ShoppingCartV2
  readonly?: boolean
  dataAttributes?: DataAttributes
}

export const Counter: React.FC<CounterProps> = (props) => {
  const {
    minimumAmount,
    maxAmount,
    amount,
    initialAmount,
    variant,
    isZeroAccepted = false,
    disabled,
    changeProductAmount,
    classes,
    minusButtonTabIndex,
    plusButtonTabIndex,
    stretchHorizontal,
    stretchVertical,
    textFieldTabIndex,
    onInputValidation,
    onSubmit,
    onTextFieldClicked,
    autofocusOnRender = false,
    readonly = false,
    dataAttributes,
    delayAutofocus,
  } = props

  const [isHovered, setIsHovered] = useState(false)
  const [userInputAmount, setUserInputAmount] = useState<number>(amount)
  const theme = useTheme()
  const textFieldRef = useRef<HTMLInputElement>(null)
  const initialAmountRef = useRef(0)
  const changeProductAmountRef = useRef(changeProductAmount)

  useEffect(() => {
    if (!autofocusOnRender) return
    if (delayAutofocus) {
      const timeout = setTimeout(() => {
        textFieldRef.current?.focus()
      }, 300)

      return () => clearTimeout(timeout)
    }
  }, [autofocusOnRender, delayAutofocus])

  useEffect(() => {
    const initial = initialAmount || 0

    const update = (base: React.ReactText) => {
      const { nextValidAmount, isValid } = getNextValidAmount(
        Number(base),
        minimumAmount,
        maxAmount
      )
      if (isValid) {
        initialAmountRef.current = initial
      }
      return nextValidAmount
    }

    if (initial === initialAmountRef.current) {
      changeProductAmountRef.current((base) => {
        return update(base)
      })
    } else {
      changeProductAmountRef.current(() => {
        return update(initial)
      })
    }
  }, [initialAmount, minimumAmount, maxAmount])

  useEffect(() => {
    // make sure user data is always synced with local state
    setUserInputAmount(amount)
  }, [amount])

  const small = variant === 'small'

  const handleMinus = () => {
    const getNewAmount = (prevAmount) => {
      const newAmount = prevAmount - minimumAmount
      if (!isZeroAccepted) {
        if (newAmount < minimumAmount) {
          return prevAmount
        }
      } else {
        if (newAmount < 0) {
          return prevAmount
        }
      }
      return newAmount
    }
    changeProductAmount(getNewAmount)
  }

  const handlePlus = () => {
    const getNewAmount = (prevAmount) => {
      const newAmount = prevAmount + minimumAmount
      if (newAmount > defaultMaxAcceptableAmount) {
        return prevAmount
      } else if (maxAmount && newAmount > maxAmount) {
        return prevAmount
      }
      return newAmount
    }
    changeProductAmount(getNewAmount)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedAmount = parseInt(e.target.value)
    if (isNaN(updatedAmount)) {
      updatedAmount = 0
    }
    if (maxAmount && updatedAmount > maxAmount) {
      updatedAmount = maxAmount
    }
    setUserInputAmount(updatedAmount)
  }

  const handleInputValidation = (e: React.FocusEvent<HTMLInputElement>) => {
    const amount = parseInt(e.target.value)
    const { isValid, nextValidAmount } = getNextValidAmount(
      amount,
      minimumAmount,
      maxAmount,
      defaultMaxAcceptableAmount
    )
    /**
     * make sure local state always restored after validation.
     * Reason: it's not guaranteed that local state will be restored
     * automatically (in useEffect's clb) if prevValidAmount === nextValidAmount
     */
    setUserInputAmount(nextValidAmount)
    changeProductAmount(nextValidAmount)
    onInputValidation?.(isValid, nextValidAmount, amount)
  }

  const handleMouseOver = () => {
    setIsHovered(true)
  }
  const handleMouseOut = () => {
    setIsHovered(false)
  }

  const handleEnterKeyPressInTextField = () => {
    // 1) Jump back into input of header search bar and highlight term
    const el = document.getElementById('searchInputWrapper') // Header -> TopSection -> SearchInput
    if (el) {
      el.getElementsByTagName('input')[0].focus()
      el.getElementsByTagName('input')[0].select()
    }
    // 2) Get amount, validate and add to cart
    const amount = parseInt(textFieldRef.current?.value ?? '0')
    const { isValid, nextValidAmount } = getNextValidAmount(
      amount,
      minimumAmount,
      defaultMaxAcceptableAmount,
      maxAmount
    )
    if (!isValid) {
      console.warn('Add item to cart: not a valid item amount')
    }
    if (onSubmit) {
      onSubmit(nextValidAmount, userInputAmount)
    }
  }

  const handleFieldFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    if (!(e.target.readOnly || e.target.disabled)) {
      e.target.select()
    }
  }, [])

  const backgroundColor =
    isHovered && !disabled ? theme.palette.grayVariant.light5K : theme.palette.white.main
  const iconColor = disabled ? theme.palette.grayVariant.dark34K : theme.palette.grayVariant.dark

  return (
    <Box
      id="counter"
      onClick={(e) => {
        e.stopPropagation()
      }}
      sx={{ display: 'flex', flexDirection: 'row' }}
      className={styles.foo}
    >
      {!readonly && (
        <Button
          id="counter-minus"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          disabled={disabled}
          tabIndex={minusButtonTabIndex}
          variant={'outlined'}
          onClick={handleMinus}
          sx={{
            minWidth: '0',
            width: small ? '1.5rem' : '2rem',
            height: small ? '1.5rem' : '2rem',
            p: 0,
            borderRadius: '0.25rem 0 0 0.25rem',
            backgroundColor: backgroundColor,
            borderColor: theme.palette.grayVariant.light,
            '&:hover': {
              backgroundColor: backgroundColor,
              borderColor: theme.palette.grayVariant.light,
              boxShadow: '0',
            },
          }}
        >
          <SvgIcon component={MinusIcon} fontSize={'small'} htmlColor={iconColor} />
        </Button>
      )}
      <TextField
        id="counter-qty"
        autoFocus={autofocusOnRender && !delayAutofocus}
        className={clsx(
          {
            [styles.stretchHorizontal]: stretchHorizontal,
            [styles.stretchVertical]: stretchVertical,
            [styles.readonlyWrapper]: readonly,
          },
          classes?.amountField
        )}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        disabled={disabled}
        inputProps={{
          ref: textFieldRef,
          readOnly: readonly,
          ...dataAttributes,
        }}
        InputProps={{
          classes: { root: styles.readonlyInputRoot },
        }}
        onChange={handleInputChange}
        value={userInputAmount}
        sx={{
          width: small ? '4.125rem' : '5.25rem',
          height: small ? '1.5rem' : '2rem',
          padding: '0 0.25rem 0 0.25rem',
          '& .MuiOutlinedInput-root': {
            fontWeight: '700',
            borderRadius: 0,
            backgroundColor: backgroundColor,
            borderColor: theme.palette.grayVariant.light,
            '&:hover fieldset': {
              borderColor: theme.palette.grayVariant.light,
            },
            '&.Mui-focused fieldset': {
              border: '1px solid',
              borderColor: theme.palette.grayVariant.dark,
            },
            '&.Mui-disabled fieldset': {
              borderColor: theme.palette.grayVariant.light,
            },
          },
          '& .MuiOutlinedInput-input': {
            padding: '0',
            textAlign: 'center',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.light,
          },
        }}
        tabIndex={textFieldTabIndex}
        onBlur={handleInputValidation}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            //Note: The element after the current one must remain the button as currently is for the focus to work.
            if (
              e.currentTarget.nextElementSibling &&
              'focus' in (e.currentTarget.nextElementSibling ?? {})
            ) {
              ;(e.currentTarget.nextElementSibling as HTMLElement).focus()
            }
            handleEnterKeyPressInTextField()
          }
        }}
        onClick={onTextFieldClicked}
        onFocus={handleFieldFocus}
      />
      {!readonly && (
        <Button
          id="counter-plus"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          disabled={disabled}
          variant={'outlined'}
          onClick={handlePlus}
          sx={{
            minWidth: '0',
            width: small ? '1.5rem' : '2rem',
            height: small ? '1.5rem' : '2rem',
            padding: '0',
            borderRadius: '0 0.25rem 0.25rem 0',
            backgroundColor: backgroundColor,
            borderColor: theme.palette.grayVariant.light,
            '&:hover': {
              backgroundColor: backgroundColor,
              borderColor: theme.palette.grayVariant.light,
              boxShadow: '0',
            },
          }}
          tabIndex={plusButtonTabIndex}
        >
          <SvgIcon component={PlusIcon} fontSize={'small'} htmlColor={iconColor} />
        </Button>
      )}
    </Box>
  )
}
