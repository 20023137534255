import { FC, createContext, useContext } from 'react'
import { DataAttributes } from '@obeta/utils/lib/types-dom'

type DataAttributesValue = { dataAttributes: DataAttributes; active: boolean }
const DataAttributesContext = createContext<DataAttributesValue>({
  dataAttributes: {},
  active: false,
})
export const DataAttributesProvider: FC<{ value: DataAttributes }> = ({ children, value }) => (
  <DataAttributesContext.Provider value={{ dataAttributes: value, active: true }}>
    {children}
  </DataAttributesContext.Provider>
)
export const useDataAttributes = () => useContext(DataAttributesContext)
