import { createContext, useContext, FC, useEffect, useMemo } from 'react'
import { ArticleSearchParamsWithId, Etim, SelectedEtimValue } from '@obeta/models/lib/models/Search'
import { CompleteSearchProduct, MaybeCompleteSearchProduct } from '@obeta/models/lib/schema-models'
import { isCompleteSearchProduct } from '@obeta/models/lib/schema-models/utils/isCompleteSearchProduct'
import { trackCustom } from '@obeta/utils/lib/tracking'
import { useBreakpoints } from './useBreakpoints'
import { useUserDataV2 } from './useUserDataV2'
import { useSearchPageMeta } from './useSearchPageMeta'

type SearchTrackingValue = {
  queryId: string
  searchParams: ArticleSearchParamsWithId | undefined
  filters: Array<{ id: string; value: string }>
  itemsPerPage: number
  currentPage: number
}

type SearchResultsTrackingValue = {
  products: MaybeCompleteSearchProduct[]
  virtualizedPage?: number
}

type SearchDataContext = SearchTrackingValue & SearchResultsTrackingValue

const initialValue: SearchTrackingValue = {
  queryId: '',
  searchParams: undefined,
  filters: [],
  itemsPerPage: 0,
  currentPage: 0,
}

const initialResultsValue: SearchResultsTrackingValue = {
  products: [],
  virtualizedPage: 0,
}

type TrackingProduct = {
  sapId: string
  netPrice?: number
  stock?: { online: number; branch: number }
  deliveryEstimate?: {
    minDays: number
    maxDays: number
  }
}
const prepareProductForTracking = (product: CompleteSearchProduct): TrackingProduct => {
  const productStockItem = product.stock?.find((stockEntry) => stockEntry.warehouseId === '10')
  return {
    sapId: product.sapId,
    netPrice: product.prices?.netPrice,
    stock: {
      online: productStockItem?.amount ?? 0,
      branch: productStockItem?.amount ?? 0,
    },
    deliveryEstimate: {
      minDays: product.stockAvailabilityEstimate?.minValue ?? -1, // stock availability estimates fallback to -1
      maxDays: product.stockAvailabilityEstimate?.maxValue ?? -1, // stock availability estimates fallback to -1
    },
  }
}

const prepareProductForTrackingUnauthorized = (
  product: CompleteSearchProduct
): TrackingProduct => ({
  sapId: product.sapId,
})

const prepareFilterForTracking = (filter: {
  id: string
  value: string
  [key: string]: unknown
}) => {
  return { id: filter.id, value: filter.value }
}

const prepareEtimForTracking = (
  selectedEtim: Etim | undefined,
  etim: SelectedEtimValue[] | undefined
) => {
  if (!selectedEtim) return {}
  return {
    class: {
      ...selectedEtim,
    },
    attributes: (etim ?? []).map((etimAttribute) => ({
      name: etimAttribute.facet?.meta.name ?? '',
      id: etimAttribute.facet?.name ?? '',
      value: etimAttribute.value,
    })),
  }
}

const prepareTrackingContext = (
  searchContext: SearchDataContext,
  products: TrackingProduct[],
  page: number,
  hitCount: number
) => ({
  queryId: searchContext.queryId,
  products,
  hitCount,
  searchTerm: searchContext.searchParams?.searchString ?? '',
  request: {
    searchString: searchContext.searchParams?.searchString ?? '',
    hitsPerPage: searchContext.itemsPerPage,
    page,
    filters: searchContext.filters.map(prepareFilterForTracking),
    etim: prepareEtimForTracking(
      searchContext.searchParams?.selectedEtim,
      searchContext.searchParams?.etim
    ),
  },
})

const SearchTrackingContext = createContext<SearchTrackingValue>(initialValue)
export const SearchTrackingProvider = SearchTrackingContext.Provider
export const useSearchTrackingContext = () => useContext(SearchTrackingContext)

const SearchResultsTrackingContext = createContext<SearchResultsTrackingValue>(initialResultsValue)

const getMemoizedQueryId = () => localStorage.getItem('queryId')
const setMemoizedQueryId = (queryId: string) => localStorage.setItem('queryId', queryId)

export const SearchResultsTrackingProvider: FC<SearchResultsTrackingValue> = ({
  children,
  products,
  virtualizedPage,
}) => {
  const searchContext = useSearchDataContext()
  const { mobile } = useBreakpoints()
  const { isLoggedIn } = useUserDataV2()
  const { hitCount } = useSearchPageMeta()

  useEffect(() => {
    const productsToTrack = products
      .slice(
        searchContext.currentPage * searchContext.itemsPerPage,
        searchContext.currentPage * searchContext.itemsPerPage + searchContext.itemsPerPage
      )
      .filter(Boolean)
    const hasCompleteProducts = productsToTrack.every((product) => isCompleteSearchProduct(product))

    // tracking conditions are met
    if (
      getMemoizedQueryId() !== searchContext.queryId &&
      productsToTrack.length > 0 &&
      searchContext.queryId?.length > 0
    ) {
      const page = (mobile ? virtualizedPage : searchContext.currentPage) ?? 0
      if (isLoggedIn) {
        // if the user is logged in we need to wait for price and stock information
        if (hasCompleteProducts) {
          trackCustom(
            'search',
            prepareTrackingContext(
              searchContext,
              productsToTrack.map(prepareProductForTracking),
              page,
              hitCount
            )
          )
          setMemoizedQueryId(searchContext.queryId)
        }
      } else {
        trackCustom(
          'search',
          prepareTrackingContext(
            searchContext,
            productsToTrack.map(prepareProductForTrackingUnauthorized),
            page,
            hitCount
          )
        )
        setMemoizedQueryId(searchContext.queryId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, searchContext.queryId])

  return useMemo(
    () => (
      <SearchResultsTrackingContext.Provider value={{ products }}>
        {children}
      </SearchResultsTrackingContext.Provider>
    ),
    [children, products]
  )
}
export const useSearchResultsTrackingContext = () => useContext(SearchResultsTrackingContext)

export const useSearchDataContext = (): SearchDataContext => {
  const searchTrackingContext = useSearchTrackingContext()
  const searchResultsTrackingContext = useSearchResultsTrackingContext()
  return { ...searchTrackingContext, ...searchResultsTrackingContext }
}
